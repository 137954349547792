import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Layout from "./components/Layout";
// import Home from "./pages/Home";
// import Article from "./pages/Article";
// import NoPage from "./pages/NoPage";
// import UnderConstruction from "./pages/UnderConstruction";
import Landing from "./pages/Landing";
import LandingPhone from "./pages/LandingPhone";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={window.innerWidth > 800 ? <Landing /> : <LandingPhone />}
        />
        {/* <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="article" element={<Article />} />
          <Route path="*" element={<NoPage />} />
        </Route> */}
      </Routes>
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
