import React, { useState } from "react";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import { getAuth, signInAnonymously } from "firebase/auth";
import "./css/Landing.css";
import logo from "../assets/relation_logo.png";
import loader from "../assets/loader.svg";
import { FaShareSquare } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import ReCAPTCHA from "react-google-recaptcha";

const Landing = () => {
  const [popup, setPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const recaptchaRef = React.createRef();

  const auth = getAuth();
  signInAnonymously(auth)
    .then(() => {
      // Signed in
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });

  function wait7Sec() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 7000);
    });
  }

  const handleError = (errorMsg) => {
    console.error(errorMsg);
    setError(errorMsg);
  };

  const checkFieldExists = async (fieldValue) => {
    try {
      const q = query(
        collection(db, "users"),
        where("email", "==", fieldValue)
      );

      // Execute the query
      const querySnapshot = await getDocs(q);

      // Check if any documents match the query criteria
      if (querySnapshot.size > 0) {
        // Field exists in at least one document
        console.error("Field exists in at least one document");
        return true;
      } else {
        // Field does not exist in any document
        console.error("Field does not exist in any document");
        return false;
      }
    } catch (error) {
      console.error("Error checking field existence:", error);
      return false; // Return false in case of error
    }
  };

  const submitEmail = async (e) => {
    setLoading(true);
    setError("");

    if (fullName === "") {
      handleError("Please type write your full name.");
      setLoading(false);
      return;
    }

    if (email === "") {
      handleError("Please type write your email.");
      setLoading(false);
      return;
    }

    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      handleError("Please confirm that you are not a robot.");
      setLoading(false);
      return;
    }

    if (!email.includes("@") || !email.includes(".")) {
      handleError("Email is not valid.");
      setLoading(false);
      return;
    }

    if (await checkFieldExists(email)) {
      handleError("Email already signed up.");
      setLoading(false);
      return;
    }

    e.preventDefault();
    try {
      await addDoc(collection(db, "users"), {
        fullName: fullName,
        email: email,
      }).then(async () => {
        setLoading(false);
        setSuccess(true);
        await wait7Sec();
        window.location.reload();
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleShare = () => {
    window.FB.ui({
      method: "share",
      href: "https://relation.news",
    });
  };

  return (
    <div className="body">
      <div
        style={{
          backgroundImage: `url(${require("../assets/background.jpg")})`,
          zIndex: 2,
        }}
        className="upper"
      >
        <div className="logo">
          <img src={logo} alt="logo" />
          <h3>Your personal and trustable news provider</h3>
        </div>
        <div className="headerBox">
          <div className="info">
            <div className="nameBox">relation.news</div>
            <p>2024 {">"} </p>
          </div>
          <h1>
            With a single subscription, you stay up to date on the news from
            different sources: local, national as well as global.
          </h1>
        </div>
      </div>
      <div className="lower">
        <div className="lowerContent">
          <div className="signUpTextBox">
            <h3>Sign up to get free access to the preview release</h3>
            <p>The first 1000 subscribers gets a 3 months free subscription</p>
          </div>
          <div className="signUpBtnBox">
            <div className="signUpBtn" onClick={() => setPopup(true)}>
              Sign up here
            </div>
          </div>
          <FaShareSquare className="shareBtn" size={30} onClick={handleShare} />
        </div>
      </div>
      <div className={`popupContainer ${popup ? "active" : ""}`}>
        <div className="popup">
          <div className="popupHeader">
            <h3 className="error">{error}</h3>
            <IoIosClose
              className="closeBtn"
              size={40}
              onClick={() => {
                setPopup(false);
              }}
            />
          </div>
          {success ? (
            <div class="success-animation">
              <svg
                class="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  class="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  class="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
          ) : (
            <>
              <div className="inputSection">
                <h2>Name: </h2>
                <input
                  placeholder="Write your full name here..."
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                <h2>Email: </h2>
                <input
                  placeholder="Write your email here..."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="footer">
                {loading ? (
                  <img src={loader} alt="loader" />
                ) : (
                  <>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Lc_-7ApAAAAAF5ozixlrDIJ9JrPtZn7NohNDh_f"
                      size="normal"
                      className="g-recaptcha"
                      onChange={() => setCaptcha(true)}
                    />
                    <div
                      className={`footerBtn ${
                        !captcha || email === "" || fullName === ""
                          ? "disabled"
                          : ""
                      }`}
                      onClick={submitEmail}
                    >
                      Submit Email
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Landing;
