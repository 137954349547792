import React, { useState } from "react";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import { getAuth, signInAnonymously } from "firebase/auth";
import "./css/Landing.css";
import logo from "../assets/relation_logo.png";
import loader from "../assets/loader.svg";
import { FaShareSquare } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import ReCAPTCHA from "react-google-recaptcha";

const LandingPhone = () => {
  const [popup, setPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const recaptchaRef = React.createRef();

  const auth = getAuth();
  signInAnonymously(auth)
    .then(() => {
      // Signed in
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });

  function wait7Sec() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 7000);
    });
  }

  const handleError = (errorMsg) => {
    console.error(errorMsg);
    setError(errorMsg);
  };

  const checkFieldExists = async (fieldValue) => {
    try {
      const q = query(
        collection(db, "users"),
        where("email", "==", fieldValue)
      );

      // Execute the query
      const querySnapshot = await getDocs(q);

      // Check if any documents match the query criteria
      if (querySnapshot.size > 0) {
        // Field exists in at least one document
        console.error("Field exists in at least one document");
        return true;
      } else {
        // Field does not exist in any document
        console.error("Field does not exist in any document");
        return false;
      }
    } catch (error) {
      console.error("Error checking field existence:", error);
      return false; // Return false in case of error
    }
  };

  const submitEmail = async (e) => {
    setLoading(true);
    setError("");

    if (fullName === "") {
      handleError("Please type write your full name.");
      setLoading(false);
      return;
    }

    if (email === "") {
      handleError("Please type write your email.");
      setLoading(false);
      return;
    }

    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      handleError("Please confirm that you are not a robot.");
      setLoading(false);
      return;
    }

    if (!email.includes("@") || !email.includes(".")) {
      handleError("Email is not valid.");
      setLoading(false);
      return;
    }

    if (await checkFieldExists(email)) {
      handleError("Email already signed up.");
      setLoading(false);
      return;
    }

    e.preventDefault();
    try {
      await addDoc(collection(db, "users"), {
        fullName: fullName,
        email: email,
      }).then(async () => {
        setLoading(false);
        setSuccess(true);
        await wait7Sec();
        window.location.reload();
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleShare = () => {
    window.FB.ui({
      method: "share",
      href: "https://relation.news",
    });
  };

  return (
    <div className="body">
      <div
        style={{
          backgroundImage: `url(${require("../assets/background.jpg")})`,
          zIndex: 2,
        }}
        className="upper"
      >
        <div className="logo" style={{ height: "8%" }}>
          <img src={logo} alt="logo" />
          <h3 style={{ fontSize: "2.7vw" }}>
            Your personal and trustable news provider
          </h3>
        </div>
        <div className="headerBox" style={{ width: "75%" }}>
          <div className="info" style={{ height: "20%" }}>
            <div className="nameBox" style={{ fontSize: "2.5vw" }}>
              relation.news
            </div>
            <p style={{ fontSize: "2vw" }}>2024 {">"} </p>
          </div>
          <h1 style={{ height: "70%", fontSize: "4.3vw", lineHeight: "4vh" }}>
            With a single subscription, you stay up to date on the news from
            different sources: local, national as well as global.
          </h1>
        </div>
      </div>
      <div className="lower">
        <div
          className="lowerContent"
          style={{ height: "77%", justifyContent: "space-evenly" }}
        >
          <div className="signUpTextBox">
            <h3 style={{ fontSize: "4vw" }}>
              Sign up to get free access to the preview release
            </h3>
            <p style={{ fontSize: "2.7vw" }}>
              The first 1000 subscribers gets a 3 months free subscription
            </p>
          </div>
          <div className="signUpBtnBox" style={{ width: "50%" }}>
            <div
              className="signUpBtn"
              style={{ padding: "8% 15%", fontSize: "4vw" }}
              onClick={() => setPopup(true)}
            >
              Sign up here
            </div>
          </div>
          <FaShareSquare className="shareBtn" size={25} onClick={handleShare} />
        </div>
      </div>
      <div className={`popupContainer ${popup ? "active" : ""}`}>
        <div className="popup" style={{ height: "70%", width: "80%" }}>
          <div className="popupHeader">
            <h3 className="error" style={{ fontSize: "2.7vw" }}>
              {error}
            </h3>
            <IoIosClose
              className="closeBtn"
              size={40}
              onClick={() => {
                setPopup(false);
              }}
            />
          </div>
          {success ? (
            <div class="success-animation">
              <svg
                class="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  class="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  class="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
          ) : (
            <>
              <div
                className="inputSection"
                style={{ height: "50%", justifyContent: "center" }}
              >
                <h2 style={{ fontSize: "3.3vw", marginTop: "0%" }}>Name: </h2>
                <input
                  style={{ fontSize: "4.2vw", padding: "2.2%" }}
                  placeholder="Write your full name here..."
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                <h2 style={{ fontSize: "3.3vw", marginTop: "20%" }}>Email: </h2>
                <input
                  style={{ fontSize: "4.2vw", padding: "2.2%" }}
                  placeholder="Write your email here..."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div
                className="footer"
                style={{ height: "30%", justifyContent: "space-around" }}
              >
                {loading ? (
                  <img src={loader} alt="loader" />
                ) : (
                  <>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Lc_-7ApAAAAAF5ozixlrDIJ9JrPtZn7NohNDh_f"
                      size="normal"
                      className="g-recaptcha"
                      onChange={() => setCaptcha(true)}
                    />
                    <div
                      style={{ fontSize: "3.8vw", padding: "5% 8%" }}
                      className={`footerBtn ${
                        !captcha || email === "" || fullName === ""
                          ? "disabled"
                          : ""
                      }`}
                      onClick={submitEmail}
                    >
                      Submit Email
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPhone;
